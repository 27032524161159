var BMWSERVIS = {
    init: function() {
        $('#light-gallery').lightGallery({
            thumbnail : false,
            counter : true
        });
    }
};

















